<template>
  <div class="content-detail-wrap flex">
    <Left v-if="!isfoldLeft" id="left-wrap" class="left" :order-information="orderInformation" :title-type="titleType" />
    <div :class="['middle card-padding', isfoldLeft ? 'middle-all' : 'middle-half']">
      <div id="scroll-wrap" ref="scrollWrap" class="scroll-wrap">
        <div class="table-wrap">
          <div class="arrow" :class="isfoldLeft ? 'arrow-left' : 'arrow1'" @click="foldLeft">
            <i :class="isfoldLeft ? 'el-icon-arrow-right' : 'el-icon-arrow-left'" />
            <span v-if="isfoldLeft">{{ titleType }}信息</span>
          </div>
          <div class="tabs-wrap">
            <Mtabs :tabs="tabs" class="tabs" :init-tab-index="activeTabIndex" @changeTab="changeTab" @refresh="refreshData" />
          </div>
          <div class="tab-down-wrap">
            <!-- 未找车详情 -->
            <template v-if="status===0">
              <AcceptPushInfoDetailLookCar v-if="activeTabIndex ===0" :key="componentKey" :order-id="Number(orderId)" :record-id="recordId" :order-information="orderInformation" @toSendDetail="toSendDetail" />
            </template>
            <!-- 已找车详情 -->
            <template v-if="status===1">
              <AcceptPushInfoDetailLookCared v-if="activeTabIndex ===0" :key="componentKey" :order-id="Number(orderId)" :record-id="recordId" :show-records="true" :show-actions="![8,9].includes(+orderInformation.state)" :order-information="orderInformation" @actionSuccess="refreshCount" />
              <WaitQXReprot v-if="activeTabIndex === 1" :key="componentKey" :order-id="Number(orderId)" :is-detail="true" :order-information="orderInformation" />
              <WaitOwnerConfirm v-if="activeTabIndex === 2" :key="componentKey" :order-id="Number(orderId)" />
              <OwnerConfirmed v-if="activeTabIndex === 3" :key="componentKey" :order-id="Number(orderId)" :record-id="recordId" :type="2" />
              <ReassignAndCancel v-if="activeTabIndex === 4" :key="componentKey" :order-id="Number(orderId)" type="XW" />
            </template>
            <!-- 找车记录详情 -->
            <template v-if="status===2">
              <AcceptPushInfoDetailLookCared v-if="activeTabIndex ===0" :key="componentKey" :order-id="Number(orderId)" :record-id="recordId" :show-records="true" :show-actions="false" :order-information="orderInformation" />
              <OwnerConfirmed v-if="activeTabIndex === 1" :key="componentKey" :order-id="Number(orderId)" :record-id="recordId" :type="4" />
              <ReassignAndCancel v-if="activeTabIndex === 2" :key="componentKey" :order-id="Number(orderId)" type="XW" />
            </template>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div v-show="showBackTop" class="back-to-top" @click="backToTop">
          <i class="el-icon-top" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import supplyLookCar from '@/api/goodsOrder/supplyLookCar';
import Left from '@/components/OrderBusiness/left';
import Mtabs from '@/components/OrderBusiness/Mtabs';
import AcceptPushInfoDetailLookCar from '@/views/goodsOrder/components/detail/XW/acceptPushInfoDetailLookCar';
import AcceptPushInfoDetailLookCared from '@/views/goodsOrder/components/detail/XW/acceptPushInfoDetailLookCar';
import WaitQXReprot from '@/views/goodsOrder/components/detail/XW/waitQXReprot';
import WaitOwnerConfirm from '@/views/goodsOrder/components/detail/XW//waitOwnerConfirm';
import OwnerConfirmed from '@/views/goodsOrder/components/detail/ownerConfirmed';
import ReassignAndCancel from '@/views/goodsOrder/components/detail/reassignAndCancel';
export default {
  // name: 'GoodsorderSupplylookcarDetail',
  components: { Left, Mtabs, AcceptPushInfoDetailLookCar, AcceptPushInfoDetailLookCared, WaitQXReprot, WaitOwnerConfirm, OwnerConfirmed, ReassignAndCancel },
  data() {
    return {
      titleType: '货源',
      componentKey: 1,
      orderId: '',
      recordId: '',
      status: '',
      orderInformation: {}, // 货单信息
      activeTabIndex: 0,
      tabMap: {
        0: [
          { label: '承接推送信息', value: 0 },
        ],
        1: [
          { label: '承接推送信息', value: 0, taskNum: 0 },
          { label: '待前线上报', value: 1, taskNum: 0 },
          { label: '待货主确认', value: 2, taskNum: 0 },
          { label: '货主确认', value: 3, taskNum: 0 },
          { label: '改派/取消', value: 4, taskNum: 0 },
        ],
        2: [
          { label: '承接推送信息', value: 0, taskNum: 0 },
          { label: '货主确认', value: 1, taskNum: 0 },
          { label: '改派/取消', value: 2, taskNum: 0 },
        ],
      },
      tabs: [],
      isfoldLeft: false, // 左侧是否展开铺满
      showBackTop: false,
      countInfo: {},
    };
  },
  created() {
    this.showDetail();
  },
  mounted() {
    const box = this.$refs.scrollWrap;
    box.addEventListener(
      'scroll',
      () => {
        this.showBackTop = this.$refs.scrollWrap.scrollTop > 215;
      },
      false,
    );
  },
  methods: {
    initTab() {
      this.orderId = +this.$route.query.id;
      this.recordId = +this.$route.query.recordId || 0;
      this.status = +this.$route.query.status;
      this.activeTabIndex = +this.$route.query.tabIndex || 0;
      this.tabs = this.tabMap[this.status];
      if (this.status !== 0) {
        this.getCount();
      }
    },
    showDetail() {
      this.initTab();
      this.getDetail();
    },
    getCount() {
      supplyLookCar.orderGoodsRecordTransCount({ id: this.orderId, var1: this.recordId }).then(res => {
        this.countInfo = res || {};
      }).finally(() => {
        const tabsMap = {
          1: [{ label: '承接推送信息', value: 0, taskNum: this.countInfo.pushCount },
            { label: '待前线上报', value: 1, taskNum: this.countInfo.readySubmitGoodsCount },
            { label: '待货主确认', value: 2, taskNum: this.countInfo.readyGoodsConfirmCount },
            { label: '货主确认', value: 3, taskNum: this.countInfo.goodsPassConfirmCount },
            { label: '改派/取消', value: 4, taskNum: this.countInfo.changeCount || 0 + this.countInfo.cancelCount || 0 }],
          2: [{ label: '承接推送信息', value: 0, taskNum: this.countInfo.pushCount },
            { label: '货主确认', value: 1, taskNum: this.countInfo.goodsPassConfirmCount },
            { label: '改派/取消', value: 2, taskNum: this.countInfo.changeCount + this.countInfo.cancelCount }],
        };
        this.tabs = tabsMap[this.status];
      });
    },
    refreshCount() {
      if (this.status !== 0) {
        this.getCount();
      }
    },
    // 货单信息+货单详情时间轴
    async getDetail() {
      const detail = await supplyLookCar.orderGoodsDetail(this.orderId).then((res) => {
        return res || {};
      });
      this.orderInformation = detail;
      if (this.status === 1 && this.orderInformation.state === 8) {
        // 场景：本身停留在承接中详情 此时刷新 此单已报停 直接跳转到承接记录（已报停）
        this.$router.replace({
          path: '/goodsOrder/supplyLookCar/detail',
          query: {
            id: this.orderId,
            status: 2,
            recordId: this.recordId,
          },
        });
        this.initTab();
      }
    },
    // 左侧展开
    foldLeft() {
      this.isfoldLeft = !this.isfoldLeft;
    },
    // 切换tab
    changeTab(data) {
      this.activeTabIndex = Number(data.value);
      // 暂无多余tab接入 暂时写法 后期提炼
      this.componentKey = this.componentKey + 1;
      this.refreshCount();
    },
    // tab下重新刷新tab数据
    refreshData() {
      this.componentKey = this.componentKey + 1;
      this.refreshCount();
    },
    // 来自货源找车操作后 刷新页面 实现载入已找车详情效果
    toSendDetail() {
      this.showDetail();
    },
    backToTop() {
      const container = document.querySelector('#scroll-wrap');
      container.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/goodsOrder.scss";
</style>
