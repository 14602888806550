<template>
  <!-- 货源找车（小五）-待前线上报/待货主确认 -->
  <div class="carrier-report-wrap">
    <div class="card-title">提交：</div>
    <div class="flex">
      <el-col :span="5" class="flex-c">
        <div class="label bold-text">总计： </div>
        <div class="value"> {{ reportList.length||"-" }} 车</div>
      </el-col>
      <el-col :span="5">
        <el-input v-model="form.truckNo" placeholder="输入车牌号/姓名">
          <i slot="suffix" class="cursor hover-main el-input__icon el-icon-search" @click="search" />
        </el-input>
      </el-col>
    </div>
    <div class="g-search-form-table">
      <Table
        :table-cols="tableCols"
        :table-data="reportList"
        :show-pagination="false"
      >
        <template v-slot="{ col, row }">
          <div v-if="col.prop === 'price'" class="ellipsis">
            <span v-if="+row.priceType===2" class="mr5">{{ $parseNumber(row.showPrice) }}</span>
            <span v-else class="mr5">{{ $parseNumber(row.price) }}</span>
            <span :class="$CONSTANT.taxColorMap[+row.needTax]">{{ $CONSTANT.taxMap[+row.needTax]||'-' }}</span>
          </div>
          <div v-if="col.prop === 'infoPrice'" class="ellipsis">
            <span>{{ +row.msgPriceType===2?$parseNumber(row.msgPrice):'-' }}元/车</span>
            <!-- <span v-if="+row.priceType===2" :class="$CONSTANT.taxColorMap[+row.needTax]">{{ $CONSTANT.taxMap[+row.needTax]||'-' }}</span> -->
          </div>
          <!-- <div v-if="col.prop === 'payFast'" class="ellipsis">
            <span :class="$CONSTANT.taxColorMap[+row.payFast]" class="mr5">{{ $CONSTANT.landMap[+row.payFast]||'-' }}</span>
          </div> -->
          <div v-if="col.prop === 'truckNo'" class="ellipsis">
            <span class="mr5">{{ row
              .truckNo||'-' }}</span>
            <span :class="$CONSTANT.carTypeColorMap[row.isWx]">{{ $CONSTANT.carTypeMap[row.isWx]||'-' }}</span>
          </div>
          <div v-if="col.prop === 'driverName'" class="ellipsis">
            <span class="mr5">{{ row.driverName||'-' }}</span>
            <span>{{ row.driverPhone||'-' }}</span>
          </div>
          <div v-if="col.prop === 'escortName'" class="ellipsis">
            <span class="mr5">{{ row.escortName||'-' }}</span>
            <span>{{ row.escortPhone||'-' }}</span>
          </div>
          <div v-if="col.prop === 'sourceType'" class="ellipsis">
            <span>平台定向</span>
          </div>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import supplyLookCar from '@/api/goodsOrder/supplyLookCar';
export default {
  props: {
    orderId: {
      type: [Number, String],
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      pushSetBaseInfo: {}, // 推送设置基础数据
      form: {},
      tableCols: [
        { label: `上报价格（元/吨）`, prop: 'price', width: 138, type: 'customize' },
        { label: '信息费', prop: 'infoPrice', type: 'customize' },
        { label: '车牌号', prop: 'truckNo', type: 'customize' },
        { label: '挂车号', prop: 'guaNo' },
        { label: '驾驶员', prop: 'driverName', type: 'customize', width: 175 },
        { label: '押运员', prop: 'escortName', type: 'customize', width: 175 },
        { label: '预装（吨）', prop: 'goodsWeight', type: 'numberTransition' },
        { label: '上报时间', prop: 'createTime', type: 'filterTime', width: 165 },
        { label: '报车渠道', prop: 'sourceType', type: 'customize', rulesObj: this.$CONSTANT.sourceMap },
        { label: '承运商用户', type: 'userInfoCard', userType: 2, width: 165 },
      ],
      reportList: [],
    };
  },
  created() {
    this.getBasePushSet();
    //  this.getList();
  },
  methods: {
    // 推送设置详情
    getBasePushSet() {
      supplyLookCar.orderGoodsXwPushDetail(this.orderId).then(res => {
        this.pushSetBaseInfo = {
          priority: res.priority || 3,
          isPushPlatForm: res.isPushPlatForm || 0,
          priceType: res.priceType || 1,
          price: this.$parseNumber(res.price) || 0,
          priceUnit: res.priceUnit || 1,
          needTax: res.needTax || 1,
        };
      }).finally(() => {
        this.getList();
      });
    },
    getList() {
      const params = {
        search: this.form.truckNo,
        gId: this.orderId,
        state: 2, // 1待提交货主 2待货主确认
        enable: 1, // 没有取消的
      };
      supplyLookCar.orderCarXwList(params).then(res => {
        this.reportList = res?.records || [];
      }).finally(() => {
        this.tableCols[0].label = `上报价格（${this.$CONSTANT.transportPriceUnitMap.O[+this.pushSetBaseInfo.priceUnit || 1]}）`;
        if (+this.pushSetBaseInfo.priceUnit === 3) {
          this.tableCols[0].width = 153;
        }
      });
    },
    search() {
      this.getList();
    },
  },
};

</script>
<style lang='scss' scoped>
.carrier-report-wrap{
  .info-item{
    width:350px;
    display: flex;
    line-height: 40px;
  }
  .label{
    text-align: right;
    width:100px;
    color: #A4A4A4;
  }
  .value{
    flex: 1;
  }
  .bold-text{
    font-weight: bold;
    color: $Black;
  }
  .g-search-form-table{
    margin-bottom: 10px;
  }
}
</style>
