<template>
  <!-- 货主确认 -->
  <div class="carrier-report-wrap">
    <div class="flex mb20">
      <el-col :span="5" class="flex-c">
        <div class="label bold-text">总计确认 </div>
        <div class="value"> <span class="main-c"> {{ confirmInfo.carCount||"-" }} </span>车</div>
      </el-col>
      <el-col v-if="type===1" :span="12" class="flex-c">
        <div class="label bold-text">找车指导价：</div>
        <div class="value">
          <span class="mr10">{{ $CONSTANT.priceTypeMap[+confirmInfo.priceType] }}</span><span>{{ $parseNumber(confirmInfo.price)||'-' }} {{ $CONSTANT.transportPriceUnitMap.O[+confirmInfo.priceUnit] }}</span>
          <span :class="['ml10',$CONSTANT.taxColorMap[+confirmInfo.needTax]]">{{ $CONSTANT.taxMap[+confirmInfo.needTax] }}</span>
          <span class="grey-c fz_12 ml10">预估利润率 {{ confirmInfo.profit }}%</span>
        </div>
        <!-- <div v-else class="value">
          <span class="mr10">信息费</span><span>{{ reportInfo.toPrice||'-' }} 元/车</span>
          <span :class="['ml10',$CONSTANT.taxColorMap[+confirmInfo.needTax]]">{{ $CONSTANT.taxMap[+confirmInfo.needTax] }}</span>
          <span class="grey-c fz_12 ml10">预估利润率 10%</span>
        </div> -->
      </el-col>
    </div>
    <div class="flex-c mb20">
      <el-col :span="5">
        <el-input v-model="form.keyword" placeholder="输入车牌号/姓名">
          <i slot="suffix" class="cursor hover-main el-input__icon el-icon-search" @click="search" />
        </el-input>
      </el-col>
      <div v-if="['PT004'].includes($store.state.common.AUV_ROLE_CODE)" class="main-c-cursor ml20" @click="$router.push(`/transport/goods/detail?id=${orderId}`)">查看运输详情</div>
    </div>
    <div v-for="(pitem, pindex) in tableData" :key="pindex" class="g-search-form-table">
      <div class="mb20">运单编号：{{ pitem.transSerial||'-' }}</div>
      <div v-for="(item, index) in pitem.carOrderReportGroupVOList" :key="index" class="mb20">
        <div class="mb20">
          <div class="flex-c mb20">
            <UserInfoCard :user-info="item" :user-type="2" />
            <div v-if="item.carOrderListVOList&&item.carOrderListVOList[0]&&item.carOrderListVOList[0].carOrderSimpleListVO" class="ml20 red-c">改：{{ item.carOrderListVOList[0].carOrderSimpleListVO?item.carOrderListVOList[0].carOrderSimpleListVO.truckNo||'-':'-' }}</div>
          </div>
          <div class="flex-c">
            <div class="mr20">
              <div class="value">
                <span class="mr10">上报 {{ item.submitCarCount }} 车</span>
                <span class="mr10">上报价格</span>
                <span>{{ $parseNumber(item.submitPrice)||'-' }} {{ $CONSTANT.transportPriceUnitMap.O[+confirmInfo.priceUnit] }}</span>
                <span :class="['ml5',$CONSTANT.taxColorMap[+item.needTax]]">{{ $CONSTANT.taxMap[+item.needTax] }}</span>
                <span class="ml10 mr10">信息费</span>
                <span>{{ +item.msgPriceType===2?$parseNumber(item.msgPrice):'-' }}元/车</span>
              </div>
            </div>
            <div>报车渠道：平台定向</div>
          </div>
        </div>
        <Table
          :table-cols="tableCols"
          :table-data="item.carOrderListVOList"
          :show-pagination="false"
        >
          <template v-slot="{ col, row }">
            <div v-if="col.prop === 'truckNo'" class="ellipsis">
              <span class="mr5">{{ row.truckNo||'-' }}</span>
              <span :class="$CONSTANT.carTypeColorMap[row.isWx]">{{ $CONSTANT.carTypeMap[row.isWx]||'-' }}</span>
            </div>
            <div v-if="col.prop === 'driverName'" class="ellipsis">
              <span class="mr5">{{ row.driverName||'-' }}</span>
              <span>{{ row.driverPhone||'-' }}</span>
            </div>
            <div v-if="col.prop === 'escortName'" class="ellipsis">
              <span class="mr5">{{ row.escortName||'-' }}</span>
              <span>{{ row.escortPhone||'-' }}</span>
            </div>
          </template>
        </Table>
      </div>
    </div>
  </div>
</template>

<script>
import supplyTask from '@/api/goodsOrder/supplyTask';
import supplyLookCar from '@/api/goodsOrder/supplyLookCar';
import { numberCalculation } from '@/utils';
export default {
  props: {
    orderId: {
      type: [Number, String],
      default() {
        return 1;
      },
    },
    // 1 货源承接-承接中 2 货源找车 3货源承接-承接记录 4 货源找车-找车记录
    type: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    recordId: {
      type: [Number, String],
      default() {
        return 1;
      },
    },
  },

  data() {
    return {
      numberCalculation,
      form: {},
      tableCols1: [
        { label: '车单编号', prop: 'carSerial', width: 180 },
        { label: '车牌号', prop: 'truckNo', type: 'customize' },
        { label: '挂车号', prop: 'guaNo' },
        { label: '驾驶员', prop: 'driverName', type: 'customize', width: 160 },
        { label: '押运员', prop: 'escortName', type: 'customize', width: 160 },
        { label: '货主确认时间', prop: 'confirmTime', type: 'filterTime', width: 165 },
        { label: '小五姓名', type: 'objShow', objShow: { keyPar: 'xwUserVO', keyChild: 'name' }},
      ],
      tableCols2: [
        { label: '车单编号', prop: 'carSerial', width: 160 },
        { label: '车牌号', prop: 'truckNo', type: 'customize' },
        { label: '挂车号', prop: 'guaNo' },
        { label: '驾驶员', prop: 'driverName', type: 'customize', width: 160 },
        { label: '押运员', prop: 'escortName', type: 'customize', width: 160 },
        { label: '货主确认时间', prop: 'confirmTime', type: 'filterTime', width: 165 },
      ],
      confirmInfo: {},
      tableData: [],
      tableDataFixed: [],
    };
  },
  computed: {
    tableCols() {
      return this.type === 1 ? this.tableCols1 : this.tableCols2;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let params;
      if (+this.type === 2 || +this.type === 4) {
        params = {
          id: this.orderId,
          var1: this.recordId,
        };
      } else {
        params = {
          id: this.orderId,
        };
      }
      const apiMap = {
        1: supplyTask.orderCarConfirmQxList,
        2: supplyLookCar.orderCarConfirmXwList,
        3: supplyTask.orderCarConfirmQxList,
        4: supplyLookCar.orderCarConfirmXwList,
      };
      apiMap[+this.type](params).then(res => {
        this.confirmInfo = res || {};
        this.tableData = res?.qxReportGroupVOList	 || [];
        this.tableDataFixed = res?.qxReportGroupVOList	 || [];
      });
    },
    // 前端mock搜索
    getFiltersList(reportList, keyword) {
      return reportList.map(item => ({
        ...item,
        carOrderReportGroupVOList: item.carOrderReportGroupVOList.map(report => ({
          ...report,
          carOrderListVOList: report.carOrderListVOList.filter(order => order.driverName.includes(keyword) || order.truckNo.includes(keyword)),
        })),
      }));
    },
    search() {
      const reportList = JSON.parse(JSON.stringify(this.tableDataFixed));
      this.tableData = this.getFiltersList(reportList, this.form.keyword);
    },
  },
};

</script>
<style lang='scss' scoped>
.carrier-report-wrap{
  .g-table-c{
    padding: 0;
  }
  .bold-text{
    font-weight: bold;
    // font-size: 16px;
    color: $Black;
  }
  .g-search-form-table{
    margin-bottom: 15px;
    width: 100%;
    padding: 15px 15px 0;
    // border: 1px solid #C0C4CC;
    border: 1px solid #EDF0F5;
    border-radius: 4px;
  }
}
</style>
